import { LoadingPage } from "components";
import { TRACK_EVENT } from "utils/mixpanel";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import VanityUrlRoutes from "routes/VanityUrlRoutes";
import { Box, Heading } from "design-system/components";
import { ErrorBody } from "pages/_error";

/**
 * This page functions to redirect users from some vanity url (in VanityUrlRoutes) to some other URL requested by marketing
 * This page also performs mixpanel tracking before redirecting the user.
 */
const VanityUrlRouter = () => {
  const router = useRouter();
  const url =
    "/" +
    (typeof router.query.vanityUrl === "string"
      ? router.query.vanityUrl
      : router.query.vanityUrl.join("/"));

  const vanityRoutes = Object.values(VanityUrlRoutes);
  const validUrlList = vanityRoutes.map((route) => route.as);

  type RouteData = {
    heading?: string;
    dataCy?: string;
    customerName?: string;
    redirectUrl?: string;
  };

  const routeData: RouteData =
    vanityRoutes.find((route) => route.as === url) || {};
  const { heading, dataCy, customerName, redirectUrl } = routeData;

  const [invalidRoute, setInvalidRoute] = useState(false);

  useEffect(() => {
    // if url in list of vanity urls, redirect to appropriate page
    if (validUrlList.includes(url)) {
      TRACK_EVENT.PAGE_VERSION_VIEWED(url, "register-directmail", {
        page_version: customerName,
      });

      router.replace(redirectUrl);
    } else {
      // otherwise, redirect to Oops page
      setInvalidRoute(true);
    }
  }, []);

  if (invalidRoute) {
    return <ErrorBody heading={"Oops!"} status={"An error occurred"} />;
  }

  return (
    <Box role="main" data-cy={dataCy}>
      <Heading as="h1" aria-label={heading}>
        {heading}
      </Heading>
      <LoadingPage />
    </Box>
  );
};

export default VanityUrlRouter;
